import { Component, Inject, Optional } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.less'],
})
export class CalendarHeaderComponent {
  constructor(
    private calendar: MatCalendar<Date>,
    @Optional() @Inject(MatDatepicker) private datepicker: MatDatepicker<Date>,
    private dateAdapter: DateAdapter<Date>
  ) {}

  setToday() {
    const today = this.dateAdapter.today();
    this.calendar.activeDate = today;
    this.calendar.selectedChange.emit(today);
    if (this.datepicker) {
      this.datepicker.select(today);
      this.datepicker.close();
    }
  }

  clearDate() {
    this.calendar.selectedChange.emit(null);
    if (this.datepicker) {
      this.datepicker.select(null);
      this.datepicker.close();
    }
  }

  previousClicked(mode: 'month' | 'year'): void {
    if (mode === 'month') {
      this.calendar.activeDate = new Date(
        this.calendar.activeDate.getFullYear(),
        this.calendar.activeDate.getMonth() - 1
      );
    } else {
      this.calendar.activeDate = new Date(
        this.calendar.activeDate.getFullYear() - 1,
        this.calendar.activeDate.getMonth()
      );
    }
  }

  nextClicked(mode: 'month' | 'year'): void {
    if (mode === 'month') {
      this.calendar.activeDate = new Date(
        this.calendar.activeDate.getFullYear(),
        this.calendar.activeDate.getMonth() + 1
      );
    } else {
      this.calendar.activeDate = new Date(
        this.calendar.activeDate.getFullYear() + 1,
        this.calendar.activeDate.getMonth()
      );
    }
  }
}